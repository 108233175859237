const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a 
      className="hover:opacity-50 transition duration-500"
      href="https://www.linkedin.com/in/paolo-villagomez/"
      target='_blank'
      rel="noreferrer"
      >
        <img src="../assets/linkedin.png" alt="linkedin-link" />
      </a>
      <a 
      className="hover:opacity-50 transition duration-500"
      href="https://twitter.com/PaoloVillag"
      target='_blank'
      rel="noreferrer"
      >
        <img src="../assets/twitter.png" alt="twitter-link" />
      </a>
      <a 
      className="hover:opacity-50 transition duration-500"
      href="https://github.com/mxsrgn"
      target='_blank'
      rel="noreferrer"
      >
        <img src="../assets/github.png" alt="github-link" />
      </a>
      <a 
      className="hover:opacity-50 transition duration-500"
      href="https://wellfound.com/u/paolo-villagomez"
      target='_blank'
      rel="noreferrer"
      >
        <img src="../assets/wellfound.png" alt="wellfound-link" />
      </a>
      {/* <a 
      className="hover:opacity-50 transition duration-500"
      href="bluesky"
      target='_blank'
      rel="noreferrer"
      >
        <img src="../assets/-----" alt="bluesky-link" />
      </a> */}

    </div>
  )
}

export default SocialMediaIcons