import LineGradient from "../components/LineGradient"
import useMediaQuery from "../hooks/useMediaQuery"
import { motion } from "framer-motion"

const MySKills = () => {
  const isAboveMediumScreens = useMediaQuery('(min-width:1060px')

  return (
    <section id='skills' className='pt-10 pb-24'>
      {/* HEADER AND IMAGE SECTION */}
      <div className='md:flex md:justify-between md:gap-16 mt-32'>
        <motion.div
          className='md:w-1/3'
          initial='hidden'
          whileInView='visible'
          viewport={{once:true, amount:0.5}}
          variants={{
            hidden: {opacity: 0, x: -50},
            visible: {opacity: 1, x: 0}
          }}
        >
          <p className='font-playfair font-semibold text-4xl mb-5'>
            MY <span className='text-red'>SKILLS</span>
          </p>
          <LineGradient width='w-1/3'/>
          <p className='mt-10 mb-7'>
          I have a strong foundation in both front-end and back-end development, coupled with a keen eye for detail. 
          As a lifelong learner, I'm eager to adopt new technologies and utilize those skills to craft intuitive and user-friendly web applications. 
          I'm always willing to collaborate with others, and if you need a website, please feel free to reach out.
          </p>
        </motion.div>
        <div className='mt-16 md:mt-0'>
          {isAboveMediumScreens ? (
            <div
              className="relative z-0 ml-20 before:absolute before:-top-10 before:-left-20  
              before:w-full before:h-full before:border-2 before:border-blue before:z-[-1]"
          >
            <img 
              src="assets/skills-image.png" 
              alt="profile" 
              className="z-10" 
            />
          </div>
          ): (
            <img 
              src="assets/skills-image.png" 
              alt="profile" 
              className="z-10" 
            />
          )}
        </div>
      </div>
      {/* SKILLS */}
      <div className='md:flex md:justify-between mt-16 gap-32'>
          {/* Responsive Design */}
          <motion.div 
            className='md:w-1/3 mt-10'
            initial='hidden'
            whileInView='visible'
            viewport={{once:true, amount:0.5}}
            variants={{
              hidden: {opacity: 0, y: 50},
              visible: {opacity: 1, y: 0}
            }}
          >
            <div className='relative h-32'>
              <div className='z-10'>
                <p className='font-playfair font-semibold text-5xl'>01</p>
                <p className='font-playfair font-semibold text-3xl mt-3'>
                  Responsive Design
                </p>
              </div>
              <div className='w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]'/>
            </div>
            <p className='mt-5'>
            I always ensure that all websites and applications I work on are responsive across all devices. I believe it is essential in providing an excellent experience for all users.
            </p>
          </motion.div>

        {/* TAILORED */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Tailored
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            When working with clients, I always build tailored websites that provide them exactly what they are looking for. This approach allows me to create digital experiences that not only meet their specific needs but also exceed their expectations, ensuring their utmost satisfaction.
          </p>
        </motion.div>

        {/* Support */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Support
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
          I maintain close collaboration with my clients, ensuring consistent communication throughout the entire process. This not only results in client satisfaction but also instills confidence in their website. 
          </p>
        </motion.div>
      </div>
    </section>
  )
}

export default MySKills